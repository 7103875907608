<template>
  <section class="latest-updates">
    <div class="latest-updates__header">
      <div class="f fs gap8">
        <svg height="32" width="32">
          <use xlink:href="/icons/sprite.svg#i-major-shanghai"></use>
        </svg>
        <h2>{{ $t('latest_cs2_update') }}</h2>
      </div>
      <p class="latest-updates-descr">
        {{ $t('armory_update_desc') }}
      </p>
    </div>
    <div class="latest-updates__body">
      <NuxtLink
          :aria-label="$t('filters.weapon.Graffities')"
          :to="{
            path: localePath('/tournaments/2024-pw-shanghai/graffities'),
            query: {}
          }"
          class="latest-updates__body_ticket"
      >
        <img alt="" src="/img/shanghai-major/graffiti.png">
        <span class="f fcol ft">
          <span class="title ell ">{{ $t('filters.weapon.Graffities') }}</span>
          <!--          <span class="subtitle">{{ $t('filters.weapon.Case') }}</span>-->
        </span>
      </NuxtLink>
      <NuxtLink
          :aria-label="$t('filters.weapon.Stickers')"
          :to="{
            path: localePath('/tournaments/2024-pw-shanghai/stickers'),
            query: {}
          }"
          class="latest-updates__body_ticket"
      >
        <img alt="" src="/img/shanghai-major/sticker.png">
        <span class="f fcol ft">
          <span class="title ell ">{{ $t('filters.weapon.Stickers') }}</span>
          <!--          <span class="subtitle">{{ $t('Skins') }}</span>-->
        </span>
      </NuxtLink>
      <NuxtLink
          :aria-label="$t('viewer_pass')"
          :to="{
            path: localePath('/tournaments/2024-pw-shanghai/collectibles'),
          }"
          class="latest-updates__body_ticket"
      >
        <img alt="" src="/img/shanghai-major/pass.png">
        <span class="f fcol ft">
                <span class="title ell ">{{ $t('viewer_pass') }}</span>
          <!--          <span class="subtitle">{{ $t('collection') }}</span>-->
              </span>
      </NuxtLink>
      <NuxtLink
          :aria-label="$t('filters.weapon.Music Kits')"
          :to="{
            path: localePath('/item/music-kit-perfect-world-ay-hey'),
          }"
          class="latest-updates__body_ticket"
      >
        <img alt="" src="/img/shanghai-major/music-kit.png">
        <span class="f fcol ft">
          <span class="title ell ">{{ $t('filters.weapon.Music Kits') }}</span>
          <!--          <span class="subtitle">{{ $t('collection') }}</span>-->
        </span>
      </NuxtLink>
      <NuxtLink
          :aria-label="$t('filters.weapon.Sticker Capsule')"
          :to="{
            path: localePath('/tournaments/2024-pw-shanghai/sticker-capsule'),
            query: {}
          }"
          class="latest-updates__body_ticket"
      >
        <img alt="" src="/img/shanghai-major/sticker-capsule.png">
        <span class="f fcol ft">
          <span class="title ell ">{{ $t('filters.weapon.Stickers') }}</span>
          <span class="subtitle">{{ $t('in_capsules') }}</span>
        </span>
      </NuxtLink>
      <NuxtLink
          :aria-label="$t('filters.weapon.Souvenirs')"
          :to="{
            path: localePath('/tournaments/2024-pw-shanghai/crates'),
            query: {}
          }"
          class="latest-updates__body_ticket"
      >
        <img alt="" src="/img/shanghai-major/souvenir.png">
        <span class="f fcol ft">
          <span class="title ell ">{{ $t('filters.weapon.Souvenirs') }}</span>
          <span class="subtitle">{{ $t('in_capsules') }}</span>
        </span>
      </NuxtLink>
      <NuxtLink
          :aria-label="$t('filters.weapon.Autographs')"
          :to="{
            path: localePath('/tournaments/2024-pw-shanghai/autograph-capsule'),
            query: { }
          }"
          class="latest-updates__body_ticket"
      >
        <img alt="" src="/img/shanghai-major/autograph-capsule.png">
        <span class="f fcol ft">
          <span class="title ell ">{{ $t('filters.weapon.Autographs') }}</span>
          <span class="subtitle">{{ $t('in_capsules') }}</span>
        </span>
      </NuxtLink>
    </div>
  </section>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
@use "/assets/scss/variables" as *;

.latest-updates {
  border-radius: 16px;
  background: $dark;
  padding: 40px;
  position: relative;
  overflow: hidden;
  margin: 40px 0 20px 0;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -20rem;
    left: -10rem;
    width: 30rem;
    height: 30rem;
    border-radius: 100%;
    background: rgba(70, 191, 201, 0.14);
    filter: blur(10rem);
  }

  &__header {
    margin-bottom: 24px;

    h2 {
      font-size: 28px;
      font-weight: 700;
    }

    svg {
      color: $green;
      flex-shrink: 0;
    }

    .latest-updates-descr {
      color: $light;
      font-size: 16px;
      font-weight: 400;
      line-height: 200%;
      margin-top: 8px;
    }
  }

  &__body {
    //display: flex;
    //flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
    gap: 12px;
    justify-content: flex-start;
    align-items: center;

    &_ticket {
      background: url("/img/ticket.png") no-repeat center;
      -webkit-background-size: contain;
      background-size: contain;
      padding: 8px 20px 8px 12px;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 56px;
      max-height: 80px;
      width: max-content;
      aspect-ratio: auto 28/9;
      transition: transform 0.3s;
      cursor: pointer;
      margin: 0 auto;

      &:hover {
        transform: rotate(-10deg);
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        max-width: 92px;
        margin-bottom: 2px;
      }

      .subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #92A1BF;
        line-height: normal;
      }

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        object-position: center;
        padding: 8px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
    margin: 20px 0;

    &__header {
      margin-bottom: 16px;

      h2 {
        font-size: 20px;
      }

      .f.fs.gap8 {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .latest-updates-descr {
        font-size: 14px;
        line-height: 22px;
      }
    }
    &__body {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 8px;

      &_ticket {
        padding: 8px 12px 8px 12px;

        &:hover {
          transform: none;
        }

        .title {
          font-size: 12px;
        }

        .subtitle {
          font-size: 10px;
        }

        img {
          width: 32px;
          height: 32px;
          padding: 8px;
          margin-right: 2px;
        }

        //&:not(:first-child) img {
        //  padding: 6px;
        //}
        //
        //&:first-child img {
        //  padding: 2px;
        //}
      }
    }
  }
}
</style>
