<template>
  <div class="container">
    <div class="main_wrap">
      <IndexComponentsBanner />
      <IndexComponentsLatestUpdates />
      <IndexComponentsSlider :items="(data?.data?.itemsPriceDiffs || []).filter(i => !!i)" />
      <IndexComponentsFeatures />
      <!--      <client-only>-->
      <!--        <div v-intersect="{callback: () => showMainTable = true}"></div>-->
      <!--      </client-only>-->
      <IndexComponentsMainTable />
    </div>
  </div>
</template>

<script lang="ts" setup>
import EventBus from "~/composables/bus";

const {locale} = useI18n()

const {data, error} = await useAsyncData('recent-items', getItemsChanges)

if (data.value?.errors?.length || error.value) {
  EventBus.$emit('notify', {
    success: false,
    msg: (data.value?.errors?.[0]?.message || error.value?.message) || 'An error occurred'
  })
  console.log('getItemsChanges() error: ', data.value?.errors?.length || error.value?.message)
}
</script>

<style lang="scss" scoped>
.main_wrap {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>

<style lang="scss">
.section {
  .section-header {
    padding: 1.6rem 0;
    font-size: 1.8rem;
  }

  border-top: 1px solid #26262c;
  padding-top: 1.6rem;
}

.home-carousel {
  padding-bottom: 2rem;
}
</style>
