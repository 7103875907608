<template>
  <section class="section flex home-carousel">
    <div class="section-header f fs">
      <h2>{{ $t('homepage.slider_title') }}</h2>
      <svg class="wh18 ml10">
        <use xlink:href="#i_round" />
      </svg>
    </div>
    <div v-if="items?.length > 0" class="section-body">
      <div class="carousel">
        <Swiper
            v-if="items?.length > 0"
            :grabCursor="true"
            :loop="false"
            :slides-per-view="'auto'"
            @init="onSwiperInit"
        >
          <SwiperSlide v-for="(item, index) of items" :key="item.localed_name">
            <ItemCard :item="item" :loading="index < 5 ? 'eager' : 'lazy'" class="mx6" />
          </SwiperSlide>
        </Swiper>
        <div
            :class="{disabled: carousel?.activeIndex === 0}"
            class="carousel-arrow arrow-prev"
            @click="prev"
        >
          <i
              class="i-arrow"
          ></i>
        </div>
        <div
            :class="{disabled: carousel?.isEnd}"
            class="carousel-arrow arrow-next"
            @click="next">
          <i
              class="i-arrow"
          ></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import ItemCard from '~/components/ItemCard/Index.vue'
import {ref} from 'vue'
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import type {Swiper as SwiperClass} from 'swiper';
import type {ItemsPriceDiffs} from "~/graphql/generated/schema-types";

const props = defineProps<{
  items: ItemsPriceDiffs[]
}>()

const carousel = ref<SwiperClass | null>(null);

const next = () => {
  if (carousel.value) {
    carousel.value.slideNext();
  }
};

const prev = () => {
  if (carousel.value) {
    carousel.value.slidePrev();
  }
};

const onSwiperInit = (e: any) => {
  if (!carousel.value) {
    carousel.value = e
  }
}

</script>
<style lang="scss" scoped>
@use "/assets/scss/components/carousel";

.carousel {
  .swiper-slide {
    width: auto;
    min-width: 176px;
    height: auto;
    align-self: stretch;
  }

  .carousel-arrow {
    opacity: 1;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
</style>
