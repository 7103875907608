<template>
  <template v-if="!isNaN(price * currency)">
    <span
        v-if="
        currencyName === 'rub' ||
        currencyName === 'krw' ||
        currencyName === 'cny' ||
        currencyName === 'jpy'
      "
        :class="`c-${currencyName}`"
    >{{ ((price / 1000) * currency).toFixed(0) }}</span
    >
    <span v-else :class="`c-${currencyName}`">{{
        ((price / 1000) * currency).toFixed(2)
      }}</span>
  </template>
</template>

<script lang="ts" setup>
import {computed} from 'vue'
import {useStore} from '~/store/index.js'

const props = defineProps<{
  price: string | number
}>()

// use store
const store = useStore()

const currencyName = computed(() => store.currency)
const currency = computed(() => store.rate)
</script>


<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;

p {
  font-weight: 500;
  font-size: 1.4rem;
  color: $white;
  margin: 0;
  display: inline;

  span {
    margin-left: 0;

    &:before {
      margin-right: 0.3rem;
    }
  }
}
</style>
